import "./App.css";
import LandingPage from "./LandingPage";
import ThankYou from "./Register/ThankYou";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga";

const TRACKING_ID = "AW-16567479659"; // Tracing Id

ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/mit-ty" element={<ThankYou />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
