import { AspectRatio, Box, Text } from "@chakra-ui/react";

const Video = () => {
  return (
    <>
      <Box
        w={["350px", "952px", "1200px"]}
        my={{ base: "10px", md: "100px" }}
        mx="auto"
        className="margin-container "
        id="admission"
      >
        <Box
          mx="auto"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDir={"column"}
        >
          <Text
            fontWeight={"600"}
            fontSize={["30px", "56px", "72px"]}
            textAlign={"center"}
            lineHeight={["32px", "68px", "82px"]}
            mb="5"
          >
            Admission for MITCAT 2024
          </Text>
          <Text fontSize={"20px"} color={"#757575"} textAlign={"center"} mb="5">
            Join MITCAT to nurture the roots of innovation and cultivate a
            thriving future ADMISSION OPEN 24-25
          </Text>

          <AspectRatio
            w={["300px", "920px", "1100px"]}
            h={["250px", "520px", "600px"]}
            mx="auto"
          >
            <iframe
              src="https://www.youtube.com/embed/8chX3H4Hr_s"
              frameborder="0"
              allow="autoplay; encrypted-media"
              allowfullscreen
              title="video"
            />
          </AspectRatio>
        </Box>
      </Box>
    </>
  );
};
export default Video;
