import {
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Img,
  useDisclosure,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import LogoImage from "../assets/MITCAT-logo.png";
import HeaderText from "./Component/HeaderText";
import React from "react";

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  return (
    <>
      <Container maxWidth={"100%"} w={"100%"} mx={"0px"}>
        <Flex justifyContent={"space-around"} alignItems={"center"} py="2">
          <Img src={LogoImage} w={{ base: "70%", md: "45%", lg: "20%" }} />
          {/* <HamburgerIcon
            ref={btnRef}
            display={{ base: "block", md: "none", lg: "none" }}
            boxSize={"10"}
            color="#3563E9"
            onClick={onOpen}
          />
          <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerBody my="14">
                <Flex
                  gap={["5", "10"]}
                  flexDir={["column", "row"]}
                  alignItems={"center"}
                  mb={["5", "0"]}
                >
                  <a href="#" onClick={() => onClose()}>
                    <HeaderText label="Home" />
                  </a>
                  <a href="#admission" onClick={() => onClose()}>
                    <HeaderText label="Admission" />
                  </a>
                  <a href="#CourseOffered" onClick={() => onClose()}>
                    <HeaderText label="Course Offered" />
                  </a>
                  <a href="#FAQ" onClick={() => onClose()}>
                    <HeaderText label="FAQ" />
                  </a>
                </Flex>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
          <Flex
            gap={["2", "10"]}
            flexDir={["column", "row"]}
            alignItems={"center"}
            mb={["5", "0"]}
            display={{ base: "none", md: "flex", lg: "flex" }}
          >
            <a href="#">
              <HeaderText label="Home" />
            </a>
            <a href="#admission">
              <HeaderText label="Admission" />
            </a>
            <a href="#CourseOffered">
              <HeaderText label="Courses Offered" />
            </a>
            <a href="#FAQ">
              <HeaderText label="FAQ" />
            </a>
          </Flex> */}
        </Flex>
      </Container>
    </>
  );
};

export default Header;
