import { Box, Text, Image, Container, SimpleGrid } from "@chakra-ui/react";
import CourseOfferedImage from "../assets/courseOffered-img.jpg";
import PrimaryPoints from "./PointsComponent";
import { MdAgriculture } from "react-icons/md";
import { FaLeaf } from "react-icons/fa";
import { RiPlantFill } from "react-icons/ri";

const PictureComponent = () => {
  const Points = [
    {
      Icon: <MdAgriculture />,
      heading: "Bachelor of Science Honours in Agriculture",
      paragraph:
        "Four-year undergraduate degree that primarily focuses on students understanding in agricultural and allied sciences.",
    },
    {
      Icon: <FaLeaf />,
      heading: "Diploma in Agriculture",
      paragraph:
        "Two years long program focusing on applied skills of agriculture methods to improve and increase productivity.",
    },

    {
      Icon: <RiPlantFill />,
      heading: "Diploma in Horticulture",
      paragraph:
        "Two years long program focusing about the skill required for cultivation vegetables, flowers, trees, and landscape architecture.",
    },
  ];
  return (
    <Container maxWidth={"8xl"} pt={{ base: "5", md: "10" }} id="CourseOffered">
      <Text
        fontSize={{ base: "20px", md: "48px" }}
        w="50%"
        lineHeight={{ base: "24px", md: "58px" }}
        mb={2}
        fontWeight={"600"}
      >
        Courses Offered
      </Text>
      <Text fontSize={{ base: "14px", md: "20px" }} color="#757575" mb={4}>
        Discover agricultural excellence with our diverse offerings:
      </Text>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} spacing={10}>
        <Box width={{ base: "100%", md: "100%" }}>
          <Image
            src={CourseOfferedImage}
            w="100%"
            objectFit="cover"
            my={{ base: "5", md: "10" }}
          />
        </Box>
        <Box width={{ base: "100%", md: "100%" }}>
          {Points.map((item) => (
            <PrimaryPoints
              key={item.Icon}
              Icon={item.Icon}
              heading={item.heading}
              paragraph={item.paragraph}
            />
          ))}
        </Box>
      </SimpleGrid>
    </Container>
  );
};

export default PictureComponent;
