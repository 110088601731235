import { Text, Box, Center } from "@chakra-ui/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import clientImage from "../assets/Avatar.png";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
const ClientFeedback = () => {
  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <ChevronLeftIcon
        onClick={onClick}
        boxSize={20}
        className="slick-prev slick-arrow"
        _hover={{ color: "black" }}
        cursor={"pointer"}
      />
    );
  };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <ChevronRightIcon
        onClick={onClick}
        boxSize={20}
        className="slick-next slick-arrow"
        cursor={"pointer"}
        _hover={{ color: "black" }}
      />
    );
  };
  const feedbackData = [
    {
      content:
        "“Orci vel eget in eu. Integer amet porttitor hendrerit etiam arcu, aliquet duis pretium consequat. Semper sed viverra enim ut nunc.”",
      AvatarImage: clientImage,
    },
    {
      content:
        "“Tincidunt risus, blandit proin semper. Tellus ac pellentesque convallis vitae. Lorem enim cursus et consequat viverra id justo ullamcorper. Vel.”",
      AvatarImage: clientImage,
    },
    {
      content:
        "“Orci vel eget in eu. Integer amet porttitor hendrerit etiam arcu, aliquet duis pretium consequat. Semper sed viverra enim ut nunc.”",
      AvatarImage: clientImage,
    },
    {
      content:
        "“Tincidunt risus, blandit proin semper. Tellus ac pellentesque convallis vitae. Lorem enim cursus et consequat viverra id justo ullamcorper. Vel.”",
      AvatarImage: clientImage,
    },
  ];
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <>
      <Box
        my={{ base: "10px", md: "100px" }}
        mx="auto"
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDir={"column"}
      >
        <Box
          w={["300px", "800px", "1200px"]}
          bg="#F7F9FE"
          mx="auto"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDir={"column"}
          my={{ base: "100px", md: 0 }}
        >
          <Center
            px={"5px"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            my={10}
          >
            <Text
              fontWeight={"600"}
              fontSize={["30px", "48px"]}
              lineHeight={["35px", "59px"]}
              w={["80%", "100%"]}
              mx="auto"
              textAlign={"center"}
            >
              Admission Queries
            </Text>
            <Text
              fontSize={"20px"}
              textAlign={"center"}
              mt={["5", "10"]}
              color="#757575"
            >
              For assistance with admissions or any inquiries regarding the
              application process, please contact our admissions office:
            </Text>
            <Text fontSize={"20px"} textAlign={"center"} color="#757575">
              Phone: +91 99653 59546 Email: admissions@mitcat.ac.in
            </Text>
            <Box
              className="themeBackground themeBorder"
              color={"white"}
              fontSize={"16px"}
              fontWeight={"500"}
              textAlign={"center"}
              py="3"
              px="10"
              w={["80%", "30%"]}
              borderRadius={"5px"}
              my="10"
            >
              <a href="#">Get Started</a>
              <ChevronRightIcon boxSize={6} />
            </Box>
          </Center>
        </Box>
      </Box>
    </>
  );
};
export default ClientFeedback;
