import { Box, Button, Flex, HStack, Img, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import LogoImage from "../assets/MITCAT-logo.png";
import BannerImage from "../../src/assets/image/MIT-View.jpg";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { ImLocation2 } from "react-icons/im";
import ReactGA from "react-ga";

const TRACKING_ID = "AW-16567479659/UH7BCLO5k7AZEOva_ts9"; // Tracing Id

ReactGA.initialize(TRACKING_ID);

export default function Register() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <>
      <Box>
        <Flex
          flexDir={{ base: "column", md: "row", lg: "row" }}
          justifyContent={"space-evenly"}
        >
          <Box h={"100vh"} w={"100%"}>
            <Img
              w="100%"
              objectFit={"cover"}
              h="100%"
              src={BannerImage}
              alt="bannerImage"
            />
          </Box>
          <Box h={"100vh"} w={"100%"}>
            <Box>
              <HStack justifyContent={"center"}>
                <Img
                  src={LogoImage}
                  w={{ base: "70%", md: "45%", lg: "50%" }}
                />
              </HStack>
              <HStack
                justifyContent={"center"}
                ms={"50px"}
                mt={"100px"}
                mb={"50px"}
              >
                <Box textAlign={"start"}>
                  <Text
                    fontSize={"36px"}
                    className="themeColor"
                    fontWeight={"bold"}
                  >
                    Thank You For Your Submission
                  </Text>
                  <Text fontSize={"20px"} w={"85%"} textAlign={""}>
                    Thank you for applying to MIT College of Agriculture &
                    Technology! We have received your information and our
                    admissions team will be in touch with you shortly.
                  </Text>
                </Box>
              </HStack>
              <HStack justifyContent={"start"} ms={"50px"}>
                <Box>
                  <HStack fontWeight={"bold"}>
                    <Text color={"rgb(100, 99, 99)"} fontSize={"3xl"}>
                      MITCAT Admission Cell
                    </Text>
                  </HStack>
                  <HStack w={"85%"}>
                    <Text color={"rgb(100, 99, 99)"} fontSize={"20px"}>
                      If you have any questions or need assistance, please
                      contact our admissions office at
                    </Text>
                  </HStack>
                  {/* phone */}
                  <Flex alignItems={"center"} mt={"20px"}>
                    <Box mx={"5px"}>
                      <BsFillTelephoneFill className="themeColor" />
                    </Box>
                    <Text
                      fontSize={"xl"}
                      color={"rgb(100, 99, 99)"}
                      fontWeight={"bold"}
                    >
                      Phone:
                    </Text>
                    <a href="tel:+91 9965359546">
                      <Text ms={"25px"} fontSize={"xl"}>
                        +91 9965359546
                      </Text>
                    </a>
                  </Flex>
                  {/* email */}
                  <Flex alignItems={"center"}>
                    <Box mx={"5px"}>
                      <MdEmail className="themeColor" />
                    </Box>
                    <Text
                      color={"rgb(100, 99, 99)"}
                      fontSize={"xl"}
                      fontWeight={"bold"}
                    >
                      Email:
                    </Text>
                    <a href="mailto:https://admission@mitcat.ac.in">
                      <Text fontSize={"xl"} ms={"35px"}>
                        admission@mitcat.ac.in
                      </Text>
                    </a>
                  </Flex>
                  {/* address */}
                  <Flex>
                    <Box mx={"5px"} mt={"6px"}>
                      <ImLocation2 className="themeColor" />
                    </Box>
                    <Text
                      color={"rgb(100, 99, 99)"}
                      fontSize={"xl"}
                      fontWeight={"bold"}
                    >
                      Address:
                    </Text>
                    <Box ms={"10px"} display={"flex"} flexDirection={"column"}>
                      <Text fontSize={"xl"}>
                        Vellalapatti (Post), Mangalam Village,
                      </Text>
                      <Text fontSize={"xl"}>
                        Tiruchirappalli District Musiri -621211,
                      </Text>
                      <Text fontSize={"xl"}>Tamilnadu.</Text>
                    </Box>
                  </Flex>
                </Box>
              </HStack>
              <HStack justifyContent={"start"} ms={"50px"} my={"30px"}>
                <Button
                  className="themeBackground"
                  color={"#fff"}
                  width={"250px"}
                  h={"48px"}
                  fontSize={"18px"}
                >
                  <a href="https://tour.mitcat.ac.in/" target="_blank">
                    MIT CAT Virtual Tour
                  </a>
                </Button>
                <Button
                  className="themeBackground"
                  color={"#fff"}
                  width={"180px"}
                  h={"48px"}
                  fontSize={"18px"}
                >
                  <a href="https://mitcat.ac.in/" target="_blank">
                    Visit Our Site
                  </a>
                </Button>
              </HStack>
            </Box>
          </Box>
        </Flex>
      </Box>
    </>
  );
}
