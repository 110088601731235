import {
  Box,
  Container,
  Divider,
  Flex,
  Image,
  Img,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import PrimaryText from "./PrimaryText";
import logoImage from "../assets/MITCAT-logo.png";
import facebookImage from "../assets/facebook-icon.svg";
import instagramImage from "../assets/instagram.svg";
import linkedInImage from "../assets/linkedIn.svg";
import youtubeIcon from "../assets/youtube.svg";

const reachUs = [
  {
    email: "admissions@mitcat.ac.in",
    phone: "+91 99653 59546",
    address:
      "Vellalapatti (Post), Mangalam Village, Tiruchirappalli District Musiri, Tamil Nadu 621211",
  },
];

const Footer = () => {
  return (
    <>
      <Box className="themeBackground">
        <Container maxW="8xl" py="16">
          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
            flexDir={{ base: "column", md: "row", lg: "row" }}
          >
            <Box
              mx="20"
              my="5"
              display={"flex"}
              flexDir={"column"}
              alignItems={{ base: "center", md: "start", lg: "start" }}
              width={{ base: "100%", md: "27%" }}
            >
              <Box>
                <VStack alignItems={{ base: "center", md: "start" }}>
                  <Img className="logoFooter" src={logoImage} alt="logo" />
                  <Text
                    textAlign={{ base: "center", md: "left" }}
                    className="themeLiteColor"
                  >
                    MIT College of Agriculture and Technology is affiliated to
                    Tamil Nadu Agricultural University, Coimbatore. This
                    institution provides it students with an atmosphere for
                    multifaceted development where students are encouraged to
                    channalize their potential in the pursuit of excellence.
                  </Text>
                </VStack>
              </Box>
            </Box>
            <Box
              mx="0"
              my="12"
              display={"flex"}
              flexDir={"column"}
              alignItems={"start"}
            >
              <Text fontWeight={"700"} color={"white"}>
                Quick Links
              </Text>
              <Box marginTop={"5px"} textAlign={"left"}>
                <a href="#">
                  <Text my={"10px"} className="themeLiteColor">
                    Home
                  </Text>
                </a>
                <a href="#admission">
                  <Text my={"10px"} className="themeLiteColor">
                    Admission
                  </Text>
                </a>
                <a href="#CourseOffered">
                  <Text my={"10px"} className="themeLiteColor">
                    Courses Offered
                  </Text>
                </a>
                <a href="#FAQ">
                  <Text my={"10px"} className="themeLiteColor">
                    FAQ
                  </Text>
                </a>
              </Box>
            </Box>
            <Box
              mx="20"
              mb="12"
              display={"flex"}
              flexDir={"column"}
              alignItems={"start"}
              width={{ base: "75%", md: "27%" }}
            >
              <Text fontWeight={"700"} color={"white"}>
                Reach Us
              </Text>
              <Box>
                {reachUs.map((item, i) => {
                  return (
                    <Box key={i} marginTop={"5px"}>
                      <Text my={"10px"} className="themeLiteColor">
                        <b>Email : </b>
                        {item.email}
                      </Text>
                      <Text my={"10px"} className="themeLiteColor">
                        <b>Phone : </b>
                        {item.phone}
                      </Text>
                      <Text my={"10px"} className="themeLiteColor">
                        <b>Address : </b>
                        {item.address}
                      </Text>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Flex>
        </Container>
      </Box>
      <Box>
        <Flex
          mx={{ base: "0px", md: "80px" }}
          my={"15px"}
          flexDir={{ base: "column", md: "row", lg: "row" }}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Flex>
            <Text className="themeColor">
              © 2024 MITCAT, All Rights Reserved.
            </Text>
          </Flex>
          <Flex
            bg={"white"}
            className="socialIconSize"
            flexDir={"row"}
            my={{ base: "5", md: "0", lg: "0" }}
            gap={"5"}
          >
            <a
              href="https://www.linkedin.com/school/mit-college-of-agriculture-and-technology/"
              target="_blank"
            >
              <Img mt={"2px"} src={linkedInImage} />
            </a>
            <a href="https://www.facebook.com/MITCAT.Musiri" target="_blank">
              <Img
                width={"43px !important"}
                height={"43px !important"}
                src={facebookImage}
              />
            </a>
            <a href="https://www.instagram.com/mitcat.musiri/" target="_blank">
              <Img src={instagramImage} />
            </a>
            <a
              href="https://www.youtube.com/channel/UCks4EqJUCS4WhMAaLY9Qa-g"
              target="_blank"
            >
              <Img src={youtubeIcon} />
            </a>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default Footer;
