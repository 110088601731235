import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import Footer from "../Footer/FooterContainer";

const FrequentlyAskedQuestion = () => {
  const FAQData = [
    {
      AccordionHeading: " Why choose MITCAT?",
      AccordionContent:
        "MITCAT is committed in bringing all the students reach their personal, academic and professional goals. We strongly believe in building community-driven and hands-on learning to prepare students for challenging future.",
    },
    {
      AccordionHeading:
        "What sets MITCAT apart from other agricultural colleges?",
      AccordionContent:
        "At MITCAT, we pride ourselves on our innovative curriculum, state-of-the-art facilities, and dedicated faculty who are experts in their fields. Our strong industry partnerships and focus on experiential learning set us apart from other institutions.",
    },
    {
      AccordionHeading: "Which academic programs does MITCAT offer?",
      AccordionContent: (
        <ul>
          <li>Bachelor of Science in Agriculture</li>
          <li>Diploma in Agriculture</li>
          <li>Diploma in Horticulture</li>
        </ul>
      ),
    },
    {
      AccordionHeading: "What are the facilities available in MITCAT",
      AccordionContent:
        "At MITCAT, we pride ourselves on providing state-of-the-art facilities to support your academic journey. Explore our modern laboratories equipped with the latest technology, extensive library resources offering a wealth of academic materials, and our expansive College Farm facilities where agricultural exploration meets hands-on learning",
    },
    {
      AccordionHeading: "What is campus life like at MITCAT?",
      AccordionContent:
        "Immerse in rural charm and agricultural innovation at our College Farm, cultivating a lively social scene. Seamlessly integrating cutting-edge Information Technology, we equip students with essential skills for real-world success. Our advanced laboratories foster hands-on learning, nurturing problem-solving abilities. Beyond academics, our vibrant campus community offers diverse activities, enriching student life with cherished traditions and memorable events.",
    },
  ];
  return (
    <Box id="FAQ">
      <Flex
        my={{ base: "10px", md: "100px" }}
        justifyContent={"center"}
        alignItems={"center"}
        flexDir={"column"}
        px={"3px"}
      >
        <Text
          fontWeight={"600"}
          fontSize={{ base: "36px", md: "48px" }}
          textAlign={"center"}
          mb="2"
          px={"1px"}
        >
          Frequent asked questions
        </Text>
        <Text
          fontSize={{ base: "16px", md: "20px" }}
          color="#757575"
          lineHeight={{ base: "24px", md: "28px" }}
          textAlign={"center"}
          w={{ base: "100%", md: "839px" }}
          mx="auto"
        >
          Discover answers to common queries about campus life, courses, and
          facilities at MITCAT.
        </Text>

        <Box my="10">
          <Accordion
            w={{ base: "100%", md: "843px" }}
            mx="auto"
            borderTop={0}
            borderTopColor={"white"}
            my="5"
            allowToggle
          >
            {FAQData.map((item) => {
              return (
                <AccordionItem borderBottom={"1px solid #d9d9d9"}>
                  <AccordionButton borderTop={0}>
                    <Text
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontWeight={"500"}
                      fontSize={{ base: "16px", md: "20px" }}
                    >
                      {item.AccordionHeading}
                    </Text>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel>
                    <Text
                      color={"#757575"}
                      lineHeight={{ base: "20px", md: "24px" }}
                      marginLeft={"2px"}
                    >
                      {item.AccordionContent}
                    </Text>
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </Box>
      </Flex>
      <Footer />
    </Box>
  );
};

export default FrequentlyAskedQuestion;
