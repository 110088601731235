import Header from "./Header/HeaderContainer";
import PictureComponent from "./PictureComponent/PictureComponent";
import Video from "./Video/VideoContainer";
import ClientFeedback from "./WhatClientSays/ClientFeedback";
import FrequentlyAskedQuestion from "./FAQ/FrequentlyQuestions";
import NewSlide from "./Slide/newSlide";
import Register from "./Register/Register";
import ReactGA from "react-ga";
import { useEffect } from "react";

const TRACKING_ID = "AW-16567479659"; // Tracing Id

ReactGA.initialize(TRACKING_ID);

const LandingPage = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <>
      <Header />
      <Register />
      <Video />
      <PictureComponent />
      {/* <NewSlide /> */}
      <ClientFeedback />
      <FrequentlyAskedQuestion />
    </>
  );
};
export default LandingPage;
