import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormLabel,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import BannerImage from "../../src/assets/image/MIT_CAT_Home.jpg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import ThankYouPage from "./ThankYou";

export default function Register() {
  const [interested, setInterested] = useState(false);
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState(
    "Bachelor_of_Science_Honours_in_Agriculture"
  );

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setInterested(isChecked);
    // Call the global function to update the checkbox value in the HTML
    window.updateCheckboxValue(isChecked);
  };
  const handleFormSubmit = (event) => {
    event.preventDefault();

    const myForm = event.target;
    const formData = new FormData(myForm);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => navigate("/mit-ty/"))
      .catch((error) => alert(error));
  };
  return (
    <>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }}>
        <Box display={{ base: "none", md: "block", lg: "block" }}>
          <Image
            w="100%"
            h="100%"
            objectFit={"cover"}
            src={BannerImage}
            alt="bannerImage"
          />
        </Box>
        <Box>
          <Flex
            p={"20px"}
            w={{ base: "100%", md: "100%" }}
            justifyContent={"center"}
          >
            <Flex maxW="500px" flexDir={"column"} px="0" gap="2">
              <Heading
                fontWeight={"600"}
                fontSize={{ base: "3xl", md: "4xl", lg: "2xl" }}
                pb={{ base: "5", md: "4", lg: "2" }}
              >
                Enroll for New Admission Now!
              </Heading>
              <form
                name="contact"
                method="post"
                data-netlify="true"
                onSubmit={handleFormSubmit}
                action="/mit-ty/"
                data-netlify-honeypot="bot-field"
              >
                <Input type="hidden" name="form-name" value="contact" />
                <FormLabel>
                  Full Name
                  <Input
                    required
                    type="text"
                    name="name"
                    placeholder="Type your full name"
                    mb="1"
                  />
                </FormLabel>
                <FormLabel>
                  Email
                  <Input
                    required
                    type="email"
                    name="email"
                    placeholder="Type your correct email"
                    mb="1"
                  />
                </FormLabel>
                <FormLabel>
                  Location
                  <Input
                    required
                    type="text"
                    name="location"
                    placeholder="Enter your district or Pincode"
                    mb="1"
                  />
                </FormLabel>
                <FormLabel>
                  Phone Number
                  <InputGroup mb="1">
                    <InputLeftAddon>+91</InputLeftAddon>
                    <Input
                      name="phone"
                      type="tel"
                      placeholder="Mobile number"
                    />
                  </InputGroup>
                </FormLabel>
                <FormLabel as="legend" id="course-interest-label">
                  Course interested
                </FormLabel>
                <RadioGroup
                  value={selectedValue}
                  onChange={setSelectedValue}
                  mt="5px"
                  aria-labelledby="course-interest-label"
                >
                  <Stack>
                    <Flex alignItems={"center"}>
                      <Radio
                        me={"5px"}
                        id="option1"
                        name="option"
                        value="Bachelor_of_Science_Honours_in_Agriculture"
                      />
                      <FormLabel my={"0px"}>
                        Bachelor of Science Honours in Agriculture
                      </FormLabel>
                    </Flex>
                    <Flex alignItems={"center"}>
                      <Radio
                        me={"5px"}
                        id="option2"
                        name="option"
                        value="Diploma_in_Agriculture"
                      />
                      <FormLabel my={"0px"}>Diploma in Agriculture</FormLabel>
                    </Flex>
                    <Flex alignItems={"center"}>
                      <Radio
                        me={"5px"}
                        id="option3"
                        name="option"
                        value="Diploma_in_Horticulture"
                      />
                      <FormLabel my={"0px"}>Diploma in Horticulture</FormLabel>
                    </Flex>
                  </Stack>
                </RadioGroup>
                {/* dropdown */}
                {/* <FormLabel mb={"30px"}>
                  Course interested
                  <Stack mb={{ base: "-15px", md: "2" }}>
                    <Select name="interest" variant="outline">
                      <option selected hidden disabled value="">
                        Select courses
                      </option>
                      <option value="Bachelor_in_Agriculture">
                        Bachelor of Science Honours in Agriculture
                      </option>
                      <option value="Diploma_in_Agriculture">
                        Diploma in Agriculture
                      </option>
                      <option value="Diploma_in_Horticulture">
                        Diploma in Horticulture
                      </option>
                    </Select>
                  </Stack>
                </FormLabel> */}
                <FormLabel my="20px">
                  Message
                  <Textarea
                    required
                    type="text"
                    name="message"
                    placeholder="Enter your message"
                    size="sm"
                    resize="none"
                  />
                </FormLabel>
                <Flex mt="3" alignItems="start">
                  <Checkbox
                    mt={"5px"}
                    onChange={handleCheckboxChange}
                    name="agree"
                    value={interested}
                    checked={interested}
                    color={"#344054"}
                    fontWeight={"500"}
                    borderColor="green"
                  ></Checkbox>
                  <Text fontSize="sm" ml="2">
                    If you are interested in applying through the Management
                    Quota, please check the box.
                  </Text>
                </Flex>
                <Button
                  w={"100%"}
                  mt={"15px"}
                  fontWeight={"600"}
                  fontSize={"medium"}
                  className="themeBackground"
                  color={"white"}
                  type="submit"
                >
                  Submit
                </Button>
              </form>
            </Flex>
          </Flex>
        </Box>
      </SimpleGrid>
    </>
  );
}
