import { Box, SimpleGrid, Text } from "@chakra-ui/react";

const PrimaryPoints = (props) => {
  const { Icon, heading, paragraph } = props;
  return (
    <SimpleGrid
      columns={{ base: 1, sm: 2 }}
      spacing={{ base: 2, sm: 4 }}
      w={{ base: "90%", sm: "20%" }}
      mb="14"
    >
      <Box mx="5">
        <Box
          className="themeLiteBg"
          h="60px"
          w="60px"
          borderRadius={"50px"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Text fontSize={"30px"} className="themeColor" fontWeight="600">
            {Icon}
          </Text>
        </Box>
      </Box>
      <Box w={{ base: "100%", sm: "406px" }} mx="10">
        <Text color="#212121" fontWeight="600" fontSize="22px" mb="2">
          {heading}
        </Text>
        <Text fontSize="16px" lineHeight="24px" color="#616161">
          {paragraph}
        </Text>
      </Box>
    </SimpleGrid>
  );
};

export default PrimaryPoints;
